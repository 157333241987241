import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Badge,
  Label,
  Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select"

//actions
//componets
import moment from "moment"
import PropTypes from "prop-types"

//css
import "assets/scss/datatables.scss"
import UiModalUpdateVarisangya from "./UiModalUpdateVarisangya"
import UiModalAutoGenerate from "./varisangyaPeriod/UiModalAutoGenerate"

import MyPagination from "components/Common/MyPagination"
import {
  getFamilyVarisangyas,
  getLedgerDropdown,
  getMahallDropdown,
  //  deleteFamilyVarisangya
} from "store/actions"
import {
  deleteMainVarisangya,
  getAllMainVarisangyas,
} from "store/mainVarisangya/actions"
import UiModalCreateVarisangya from "./UiModalCreateVarisangya"
import DeleteModal from "components/Common/DeleteModal"
import MyPaginationNew from "components/Common/MyPagination2"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const MainVarisangyasList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [type, setType] = useState("family")
  const [period, setPeriod] = useState()
  const [isAutopay, setIsAutopay] = useState(false)
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const [ledger, setLedger] = useState("Select ledger...")
  const [ledgerId, setLedgerId] = useState()
  const [ledgerSearchText, setLedgerSearchText] = useState("")

  // const [mahall, setMahall] = useState("Search Mahall")
  // const [mahallId, setMahallId] = useState("")
  // const [mahalSearchText, setMahallSearchText] = useState("")

  const {
    allVarisangyas,
    loading,
    language,
    autoGenerateLoading,
    searchLedgers,
    searchMahalls,
  } = useSelector(state => ({
    allVarisangyas: state.MainVarisangyas.mainVarisangyas,
    loading: state.MainVarisangyas?.loading,
    language: state.Layout.language,
    autoGenerateLoading: state.FamilyVarisangyas?.autoGenerateLoading,
    searchLedgers: state.Ledgers.searchLedgers,
    searchMahalls: state.Mahalls.searchMahalls,
  }))

  console.log(allVarisangyas)

  const handleChangeTab = type => {
    setType(type)
    setPage(0)
  }

  //pages
  const totalPages = Math.ceil(allVarisangyas?.count / limit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  console.log(pageSend())

  useEffect(() => {
    dispatch(
      getAllMainVarisangyas(
        searchText,
        pageSend(),
        limit,
        type,
        ledgerId,
        "",
        period,
        isAutopay
      )
    )
  }, [dispatch, page, searchText, type, ledgerId, period, isAutopay])

  useEffect(() => {
    dispatch(getLedgerDropdown("", ledgerSearchText))
  }, [dispatch, ledgerSearchText])

  const optionLedger = [
    { label: "All", value: "" },
    {
      options: searchLedgers?.map((result, index) => ({
        key: index,
        label: `${result?.institute_ledger_name} (${
          language === "mal"
            ? result?.institute_malayalam_name
              ? result?.institute_malayalam_name
              : result?.institute_english_name
            : result?.institute_english_name
        }) (${result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setLedgerId(event.value)
    setLedger(event.label)
    setPage(0)
  }

  const handleEntersLedger = textEntered => {
    setLedgerSearchText(textEntered)
  }

  // useEffect(() => {
  //   dispatch(getMahallDropdown("", mahalSearchText))
  // }, [dispatch, mahalSearchText])

  // function handlerFinalValueMahall(event) {
  //   // dispatch(getMahallDropdown(event.value))
  //   setMahallId(event.value)
  //   setMahall(event.label)
  // }

  // const OptionsMahall = [
  //   { label: "All", value: "" },

  //   {
  //     options: searchMahalls?.map((results, index) => ({
  //       key: index,
  //       label:
  //         language === "mal"
  //           ? results.mahall_malayalam_name
  //             ? results.mahall_malayalam_name
  //             : results.mahall_english_name
  //           : results.mahall_english_name,
  //       value: results.id,
  //     })),
  //   },
  // ]

  // const handleEntersMahal = textEntered => {
  //   setMahallSearchText(textEntered)
  // }

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "title",
      text: language === "mal" ? "ടൈറ്റിൽ" : "Title",
    },
    {
      dataField: "mahall_name",
      text: language === "mal" ? "മഹൽ" : "mahal",
    },
    {
      dataField: "ledger_name",
      text: language === "mal" ? "ലെഡ്ജർ" : "Ledger",
    },
    // {
    //   dataField: "total_varisankya_amount",
    //   text: language === 'mal' ? "തുക" : "Total Amount",
    // },
    {
      dataField: "period",
      text: language === "mal" ? "കാലഘട്ടം" : "Period",
    },
    // {
    //   dataField: "pending_amount",
    //   text: language === 'mal' ? "ശേഷിക്കുന്നവ  തുക" : "Pending Amount",
    // },
    // {
    //   dataField: "status",
    //   text: language === "mal" ? "സ്റ്റാറ്റസ്" : "Status",
    // },
    // {
    //   dataField: "to_date",
    //   text: language === 'mal' ? "വർഷം" : "To",
    // },
    // {
    //   dataField: "amount",
    //   text: language === 'mal' ? "തുക" : "Amount",
    // },
    {
      dataField: "action",
      text: "",
    },
  ]

  // const handleDeleteVarisangya = id => {
  //   dispatch(deleteFamilyVarisangya(id, history))
  // }

  const handleDelete = variansangyaId => {
    setIsOpen({ status: true, id: variansangyaId })
  }
  const handleDeleteEvent = () => {
    dispatch(deleteMainVarisangya(isOpen?.id, history))
    setIsOpen({ status: false })
    // history.push("/constituencys")
  }

  const familyVarisangyasData = map(allVarisangyas?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date: item?.date && moment(item?.date).format("DD/MM/yyyy"),
    total: `${item?.total_varisankya_amount} (${item?.total_count})`,
    pending_amount: Number(
      item?.total_varisankya_amount - item?.recieved_varisankya_amount
    ).toFixed(2),
    pending: `${Number(
      item?.total_varisankya_amount - item?.recieved_varisankya_amount
    ).toFixed(2)} (${item?.pending_count})`,
    is_active: (
      <>
        {
          item?.is_active ? "Active" : "Inactive"
          // <div className="form-check form-switch form-switch-md mb-3">
          //   <input
          //     type="checkbox"
          //     className="form-check-input"
          //     id="customSwitchsizemd"
          //     // onChange={(e) => {
          //     //   handleSwitchChange(e, item?.id)
          //     // }}
          //     checked={item?.is_active}
          //   />
          // </div>
        }
      </>
    ),
    status: (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          `${item?.is_active ? "success" : "danger"}`
        }
        color={"primary"}
        pill
      >
        {item?.is_active ? "Active" : "Inactive"}
      </Badge>
    ),
    action: (
      <>
        {/* {item?.is_active ? (
          <div>
            <Link to={`/family/varisangyas/${item?.id}`}>
              <Button
                type="button"
                color="success"
                className=" btn-sm mx-2"
                style={{ width: "80px" }}
              >
                {language === "mal" ? "കാണുക" : "View"}
              </Button>
            </Link>
          </div>
        ) : (
          // <div>
          //   <Button
          //     onClick={() => handleDeleteVarisangya(item?.id)}
          //     type="button"
          //     color="danger"
          //     className=" btn-sm mx-2"
          //     style={{ width: "80px" }}
          //   >
          //     {language === "mal" ? "ഡിലീറ്റ്" : "Delete"}
          //   </Button>
          // </div>
          <div>
            <Button
              onClick={() => updateVarisangyaData(item)}
              type="button"
              color="primary"
              className=" btn-sm mx-2"
              style={{ width: "80px" }}
            >
              {language === "mal" ? "എഡിറ്റ് " : "Edit"}
            </Button>
          </div>
        )} */}
        <div className="m-0">
          <Link
            to={`/varisangya/${item?.id}/${type}`}
            className="m-0 btn btn-sm btn-success me-1"
          >
            {language === "mal" ? "കാണുക" : "View"}
          </Link>
          <Button
            onClick={() => updateVarisangyaData(item)}
            className="m-0 btn btn-sm bg-primary"
          >
            {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
          </Button>
          <Button
            // id={"project" + user?.id}
            type="button"
            color="white"
            className=" btn pl-1"
            style={{}}
            onClick={() => handleDelete(item?.id)}
          >
            <i
              className="bx bx-trash "
              style={{ color: "red", fontSize: "18px" }}
            />

            {/* <UncontrolledTooltip placement="top">Delete</UncontrolledTooltip> */}
          </Button>
        </div>
      </>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceVarisangyaSearch = debounce(value => {
    setSearchText(value)
    setPage(1)
  }, 600)

  const [modalVarisangya, setModalVarisangya] = useState(false)
  const [modalUpdateVarisangya, setModalUpdateVarisangya] = useState(false)
  const [modalAutoGenerate, setModalAutoGenerate] = useState(false)
  const [onUpdateModalVarisangya, setOnUpdateModalVarisangya] = useState({})

  const updateVarisangyaData = item => {
    setModalUpdateVarisangya(true)
    setOnUpdateModalVarisangya(item)
  }
  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  // const handleAutoGenerate = () => {
  //   console.log('generate action')
  // }

  const varisangyasTypes = [
    {
      label: `${language === "mal" ? "കുടുംബം" : "Family"}`,
      id: "btnradio1",
      varisangyaType: "family",
    },
    {
      label: `${language === "mal" ? "അംഗം" : "Member"}`,
      id: "btnradio2",
      varisangyaType: "member",
    },
  ]

  const varisangyaLabel = () => {
    if (type === "member") {
      return language === "mal" ? "അംഗം" : "Member"
    }
    if (type === "family") {
      return language === "mal" ? "കുടുംബം" : "Family"
    }
  }

  const handleChangePeriod = e => {
    setPeriod(e.target.value)
    setPage(0)
    updateURLParams("filter", e.target.value)
  }

  const updateURLParams = (key, value, extraState = {}) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set("p", 1)

    if (location?.pathname !== "/dashboard") {
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: {
          ...location.state,
          page: 1,
          ...extraState,
          [key]: value,
        },
      })
    }
  }

  return (
    <React.Fragment>
      <UiModalCreateVarisangya
        show={modalVarisangya}
        onCloseclick={() => setModalVarisangya(false)}
        type={type}
      />
      <UiModalUpdateVarisangya
        show={modalUpdateVarisangya}
        onCloseclick={() => setModalUpdateVarisangya(false)}
        data={onUpdateModalVarisangya}
      />
      <UiModalAutoGenerate
        show={modalAutoGenerate}
        onCloseclick={() => setModalAutoGenerate(false)}
      />
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ status: false })}
        onDeleteClick={handleDeleteEvent}
      />

      <Row>
        {/* {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))} */}
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={familyVarisangyasData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div
                          className="btn-group d-flex justify-content-center mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {varisangyasTypes?.map((item, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item?.id}
                                autoComplete="off"
                                defaultChecked={item?.id === "btnradio1"}
                                onChange={() =>
                                  handleChangeTab(item?.varisangyaType)
                                }
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={item?.id}
                              >
                                {item?.label}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Button
                          onClick={() => setModalVarisangya(true)}
                          className="w-md btn btn-success m-auto me-0 d-block"
                          style={{ width: "auto" }}
                        >
                          {language === "mal"
                            ? `+ ${varisangyaLabel()} വരിസംഖ്യ`
                            : `+ ${varisangyaLabel()} Varisangya`}
                        </Button>
                      </div>
                    </div>

                    <Row className="mb-2">
                      <Col md="3" className="pt-2">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceVarisangyaSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <Label for="basicpill-email-input4">
                          {language === "mal" ? "ഓട്ടോപേ" : "Autopay"}
                        </Label>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            checked={isAutopay ? true : false}
                            onChange={() => setIsAutopay(!isAutopay)}
                          />
                        </div>
                      </Col> */}
                      <Col md="1">
                        <Label for="basicpill-email-input4">
                          {language === "mal" ? "കാലഘട്ടം" : "Period"}
                          {/* {language === "mal" ? "ഓട്ടോപേ" : "Autopay"} */}
                        </Label>
                        <Input
                          label="Period"
                          type="number"
                          onChange={e => handleChangePeriod(e)}
                        />
                      </Col>
                      <Col md="3">
                        <Label>
                          {language === "mal" ? "ലെഡ്ജർ" : "Ledger"}
                        </Label>
                        <Select
                          onInputChange={handleEntersLedger}
                          placeholder={ledger}
                          options={optionLedger}
                          classNamePrefix="select2-selection"
                          onChange={e => handlerFinalValue(e)}
                          type="text"
                          title="Ledger"
                          // required="required"
                        />
                      </Col>
                      {/* <Col md="3">
                        <Label>
                          {language === "mal" ? "മഹല്ല്" : "Mahall"}
                        </Label>  
                        <Select
                          onInputChange={handleEntersMahal}
                          placeholder={mahall}
                          options={OptionsMahall}
                          classNamePrefix="select2-selection"
                          onChange={e => handlerFinalValueMahall(e)}
                          type="text"
                          title="Mahall"
                          // required="required"
                        />
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : autoGenerateLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {allVarisangyas && allVarisangyas?.count === 0 && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info"
                          >
                            {language === "mal"
                              ? "വരിസംഖ്യകൾ ഒന്നും ഇല്ല"
                              : "No Varisangyas Yet"}
                          </p>
                        )}
                        <MyPaginationNew
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MainVarisangyasList

MainVarisangyasList.propTypes = {
  history: PropTypes.object,
}
