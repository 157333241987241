import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState, Component } from "react"
import { Html5QrcodeScanner } from "html5-qrcode"

import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
//componets
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  // debounce,
  map,
  range,
} from "lodash"
import moment from "moment"

import MyPagination from "components/Common/MyPagination"
import {
  getAllFamilyMembersDropdown,
  createFamilyMemberVarisangyaWallet,
  clearFamilyMemberVarisangyaWalletDetail,
  getFamilyMemberVarisangyaWalletDetail,
  getFamilyMemberDetail,
  deleteFamilyMemberVarisangyaWallet,
  getAllFamilyMemberVarisangyaWallet,
  createFamilyMemberVarisangyaWalletNew,
  getInstituteWalletDropdown,
  deleteVarisangyaWallet,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useWindowSize } from "react-use"
import { MdDelete } from "react-icons/md"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import UiModalAddFamilyMemberVarisangyaArrear from "pages/mahallUser/Varisangyas/wallet/SingleView/member/UiModalAddFamilyMemberVarisangyaArrear"

const CreateFamilyMemberVarisangyaTransaction = ({ history }) => {
  const dispatch = useDispatch()
  const [searchText] = useState("")
  const [sort] = useState("manual")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalAddArrear, setModalAddArrear] = useState(false)
  const { width, height } = useWindowSize()

  const {
    loading,
    language,
    allFamilyMembers,
    familyMemberDetail,
    allFamilyMemberVarisangyaWallet,
    tableLoading,
    searchWallets,
  } = useSelector(state => ({
    loading: state.VarisangyaWallets.loading,
    language: state.Layout.language,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    familyMemberDetail: state.FamilyMembers.familyMemberDetail,
    familyMemberVarisangyaWalletDetail:
      state.FamilyMemberVarisangyaWallets.familyMemberVarisangyaWalletDetail,
    tableLoading: state.FamilyMemberVarisangyaWallets.tableLoading,
    allFamilyMemberVarisangyaWallet:
      state.VarisangyaWallets.allFamilyMemberVarisangyaWallet,
    searchWallets: state.InstituteWallets.searchWallets,
  }))

  const totalPages = Math.ceil(allFamilyMemberVarisangyaWallet?.count / 10)
  const pages = range(1, totalPages + 1)
  const location = useLocation()

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [amount, setAmount] = useState("")
  const [remark, setRemark] = useState("")

  const [searchTextFamilyMember, setSearchTextFamilyMember] = useState("")
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(
    "Choose Family Member"
  )
  const [familyMemberId, setFamilyMemberId] = useState("")

  console.log(familyMemberId)

  const [wallet, setWallet] = useState("Select wallet...")
  const [walletId, setWalletId] = useState("")

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchTextFamilyMember))
  }, [searchTextFamilyMember, dispatch])

  useEffect(() => {
    dispatch(clearFamilyMemberVarisangyaWalletDetail())
  }, [])

  function handlerFinalValueFamilyMember(event) {
    // dispatch(getFamilyDropdown(event.value))
    setFamilyMemberId(event.value)
    setSelectedFamilyMember(event.label && event.full_name)
  }

  const optionGroupFamilyMember = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        // <div>
        //   {results?.house_name}
        //   {results?.mahall_custom_id ? (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.mahall_custom_id}
        //     </Badge>
        //   ) : results?.family_head_name ? (
        //     <span className="text-khaf">
        //       {" "}({results?.family_head_name})
        //     </span>
        //   ) : (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.custom_id}
        //     </Badge>
        //   )}
        // </div>,
        value: result?.id,
      })),
    },
  ]

  const handleEntersFamilyMember = textEntered => {
    setSearchTextFamilyMember(textEntered)
  }

  useEffect(() => {
    if (familyMemberId) {
      dispatch(getFamilyMemberDetail(familyMemberId))
      dispatch(
        getAllFamilyMemberVarisangyaWallet(
          pageSend(),
          searchText,
          "",
          "",
          location?.state?.id,
          familyMemberId
        )
      )
    }
  }, [dispatch, familyMemberId, page, searchText, location?.state?.id])

  console.log(location?.state)

  const handleReset = () => {
    setWallet("")
    setWalletId("")
    setFamilyMemberId("")
    setSelectedFamilyMember("")
    setRemark("")
    setAmount("")
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const createFamilyMemberVarisangyaWalletData = {
      family_member: familyMemberId,
      amount: amount ? parseInt(amount) : 0,
      trn_type: "credit",
      remark: remark,
      institute_wallet: walletId,
      varisankya: location?.state?.id,
    }
    // console.log(createVarisangyaData);
    if (familyMemberId && parseInt(values.amount) > 0)
      dispatch(
        createFamilyMemberVarisangyaWalletNew(
          createFamilyMemberVarisangyaWalletData,
          history,
          handleReset,
          "transaction"
        )
      )
  }

  // const handleVarisangyaArrearAdd = (onSubmitProps, values) => {
  //   const createFamilyMemberVarisangyaWalletData = {
  //     family: familyMemberId,
  //     amount: values.amount ? parseInt(values.amount) : 0,
  //     trn_type: 'debit',
  //     remark: values.remark ? values.remark : 'Arrears',
  //   }
  //   // console.log(createVarisangyaData);
  //   dispatch(
  //     createFamilyMemberVarisangyaWallet(
  //       createFamilyMemberVarisangyaWalletData,
  //       history,
  //     ),
  //   )
  // }

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "balance",
      text: language === "mal" ? "ബാലൻസ്" : "Balance",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "റിമാർക്കുകൾ" : "Remarks",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const familyMemberVarisangyaTransactionsData = map(
    allFamilyMemberVarisangyaWallet?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      amount: (
        <p
          className={
            item?.trn_type === "credit" ? "text-success" : "text-danger"
          }
        >
          {item?.amount}
        </p>
      ),
      balance: <p className="text-primary">{item?.balance}</p>,
      date: item?.date_added && moment(item?.date_added).format("DD/MM/yyyy"),
      action:
        item?.remark === "wrong entry!" ? (
          ""
        ) : (
          <Button
            color="light"
            type="button"
            className="d-flex align-items-center justify-content-center "
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(
                deleteVarisangyaWallet(
                  item?.id,
                  location?.state?.type,
                  location?.state?.id,
                  familyMemberId
                )
              )
            }
          >
            <MdDelete color="red" size={"14px"} />
          </Button>
        ),
      // action: (
      //   <div className="m-0">
      //     <Link to={`/varisangya/update/${item?.id}`} className="m-0">
      //       <Button
      //         type="button"
      //         color="primary"
      //         className=" btn w-xs btn-sm"
      //       >
      //         {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
      //         {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
      //       </Button>
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  // const debouncefamilyMemberVarisangyaWalletSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  console.log(familyMemberId)
  // useEffect(() => {
  //   function onScanSuccess(decodedText, decodedResult) {
  //     doneNotification("successfully completed")
  //     console.log(`Scan result: ${decodedText}`, decodedResult)
  //     console.log(`Scan result:`, decodedText)
  //     const FinalValue = JSON.parse(decodedText)
  //     console.log(FinalValue)

  //     if (FinalValue?.id) {
  //       setFamilyMemberId(FinalValue?.id)
  //     }

  //     html5QrcodeScanner.clear()
  //   }

  //   function onScanError(errorMessage) {
  //     errorNotification("Show the QR code in front of the camera")
  //   }

  //   const html5QrcodeScanner = new Html5QrcodeScanner("reader", {
  //     fps: 10,
  //     qrbox: 250,
  //   })

  //   html5QrcodeScanner.render(onScanSuccess, onScanError)

  //   return () => {
  //     // Cleanup when the component unmounts
  //     if (html5QrcodeScanner) {
  //       html5QrcodeScanner.clear()
  //     }
  //   }
  // }, [setFamilyMemberId])

  const [searchWallet, setSearchWallet] = useState("")

  useEffect(() => {
    dispatch(getInstituteWalletDropdown("", searchWallet))
  }, [dispatch, searchWallet])

  const handleEntersWallet = textEntered => {
    setSearchWallet(textEntered)
  }

  const optionGroupWallet = [
    {
      options: searchWallets?.map((results, index) => ({
        key: index,
        label: results.institute_wallet_name,
        value: results.id,
      })),
    },
  ]

  function handlerFinalValueWallet(event) {
    // dispatch(getLedgerItemCategoryDropdown(event.value))
    setWalletId(event.value)
    setWallet(event.label)
  }

  return (
    <>
      <UiModalAddFamilyMemberVarisangyaArrear
        show={modalAddArrear}
        onCloseclick={() => setModalAddArrear(false)}
        data={familyMemberDetail}
        varisangyaId={location?.state?.id}
      />

      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}

              <AvForm
                className="form-horizontal "
                onValidSubmit={(onSubmitProps, v) => {
                  handleValidSubmit(onSubmitProps, v)
                }}
              >
                <div className="col-md-12">
                  <div className={`col ${familyMemberId ? "mb-2" : "mb-4"}`}>
                    <Label>
                      {language === "mal" ? "കുടുംബം" : "Family Member"}
                      <span className="text-danger">&#42;</span>
                    </Label>
                    <Col sm={12}>
                      <Select
                        onInputChange={handleEntersFamilyMember}
                        placeholder={selectedFamilyMember}
                        value={selectedFamilyMember}
                        options={optionGroupFamilyMember}
                        classNamePrefix="select2-selection"
                        onChange={handlerFinalValueFamilyMember}
                        type="text"
                        title="Family Member"
                        required="required"
                      />
                    </Col>
                  </div>
                </div>
                {/* {width >= 420 ? (
                  <div id="reader" style={{ width: "555px" }}>
                    <Button>Click to scan</Button>
                  </div>
                ) : (
                  <div id="reader" style={{ width: "323px" }}>
                    <Button>Click to scan</Button>
                  </div>
                )} */}
                {familyMemberId && (
                  <Row className="mt-2">
                    <Col md="12">
                      <Card style={{ backgroundColor: "#e6fcee" }}>
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <h5 className="font-size-13 text-primary font-weight-bold">
                                {familyMemberDetail?.family_name}
                              </h5>

                              <div className="d-flex">
                                <h5 className="font-size-22 font-weight-bold mt-1">
                                  {familyMemberDetail?.full_name}
                                </h5>
                              </div>
                              <Badge
                                className="bg-primary rounded-pill"
                                style={{ fontSize: "12px" }}
                              >
                                {" "}
                                {getAge(familyMemberDetail?.dob)} |{" "}
                                {familyMemberDetail?.gender}
                              </Badge>
                              {familyMemberDetail?.mahall_custom_id && (
                                <Badge
                                  className="bg-primary rounded-pill mx-2 bg-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  {language === "mal"
                                    ? "മഹല്ല് ഐഡി"
                                    : "Mahall ID"}{" "}
                                  : {familyMemberDetail?.mahall_custom_id}
                                </Badge>
                              )}
                              <Badge className="me-1 rounded-pill bg-danger font-size-12">
                                {familyMemberDetail?.blood_group?.english}
                              </Badge>
                              {familyMemberDetail?.id_card && (
                                <h5 className="font-size-13 text-muted font-weight-bold mt-2">
                                  {language === "mal"
                                    ? familyMemberDetail?.id_card?.malayalam
                                    : familyMemberDetail?.id_card?.english}{" "}
                                  : {familyMemberDetail?.id_card_number}
                                </h5>
                              )}
                              {familyMemberDetail?.phone && (
                                <p className="mt-1 mb-2">
                                  <i className="bx bx-phone text-primary me-1" />
                                  {familyMemberDetail?.phone}
                                </p>
                              )}
                              {familyMemberDetail?.email && (
                                <p className="m-0">
                                  <i className="bx bx-mail-send text-primary me-1" />
                                  {familyMemberDetail?.email}
                                </p>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}

                <div>
                  <Label>
                    {language === "mal"
                      ? "ഇൻസ്റ്റിറ്റ്യൂട്ട് വാലറ്റ്"
                      : "Institute Wallet"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEntersWallet}
                    placeholder={wallet}
                    options={optionGroupWallet}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValueWallet}
                    type="text"
                    title="Institute Wallet"
                    required="required"
                  />
                </div>
                <Row>
                  <div className="col-md-12 mt-2">
                    <div className="col">
                      <Row>
                        <Col sm={4}>
                          <AvField
                            id="horizontal-firstname-Input"
                            name="amount"
                            type="number"
                            label={language === "mal" ? "തുക" : "Amount"}
                            onChange={e => setAmount(e.target.value)}
                            value={amount}
                          />
                        </Col>
                        <Col sm={8}>
                          <AvField
                            id="horizontal-firstname-Input"
                            name="remark"
                            type="text"
                            label={language === "mal" ? "വിവരണം" : "Remarks"}
                            onChange={e => setRemark(e.target.value)}
                            value={remark}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
                <div className="d-flex justify-content-end mt-5">
                  <Button type="submit" color="primary" className="w-md">
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        {familyMemberId && (
          <Col lg={6}>
            {familyMemberId && (
              <>
                {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-danger m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Arrears"}
                  </Button> */}
                {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-success m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Transaction"}
                  </Button> */}
              </>
            )}
            <Card>
              {familyMemberId && (
                <Col className="pt-3 px-3">
                  <Row className="d-flex align-items-center">
                    <Col>
                      <p className="text-">
                        {language === "mal"
                          ? "വാലറ്റ് ബാലൻസ് : "
                          : "Wallet Balance :"}{" "}
                        <span className="fw-bold ">
                          {familyMemberId &&
                            familyMemberDetail?.varisankya_wallet}
                        </span>
                      </p>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => setModalAddArrear(true)}
                        style={{
                          width: language === "mal" ? "170px" : "150px",
                        }}
                        className="btn btn-danger m-auto me-0 d-block"
                      >
                        <i className={"bx bx-calendar-minus mx-1"}></i>
                        {language === "mal"
                          ? "കുടിശ്ശിക ചേർക്കൽ"
                          : "Add Arrears"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              {familyMemberId && <hr />}
              <CardTitle className="mx-3 mt-3">
                {language === "mal" ? "ഇടപാടുകൾ :" : "Transactions :"}
              </CardTitle>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={familyMemberVarisangyaTransactionsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {tableLoading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-wrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          {(!allFamilyMemberVarisangyaWallet?.results ||
                            allFamilyMemberVarisangyaWallet?.results?.length <=
                              0) && (
                            <p
                              style={{ fontSize: "15px" }}
                              className="text-center text-info"
                            >
                              {language === "mal"
                                ? "ഇടപാടുകൾ ഒന്നും ഇല്ല"
                                : "No Transactions Yet"}
                            </p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </>
  )
}

export default CreateFamilyMemberVarisangyaTransaction

CreateFamilyMemberVarisangyaTransaction.propTypes = {
  history: PropTypes.object,
}
