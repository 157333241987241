import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_DETAIL,
  CREATE_FAMILY_VARISANGYA,
  UPDATE_MAIN_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  REFRESH_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  GET_ALL_MAIN_VARISANGYAS,
  CREATE_MAIN_VARISANGYA,
  UPDATE_MAIN_VARISANGYA,
  DELETE_MAIN_VARISANGYA,
} from "./actionTypes"
import {
  getFamilyVarisangyaCardsSuccess,
  getFamilyVarisangyaCardsFail,
  getFamilyVarisangyaDetailSuccess,
  getFamilyVarisangyaDetailFail,
  createFamilyVarisangyaFail,
  updateFamilyVarisangyaSuccess,
  updateFamilyVarisangyaFail,
  deleteFamilyVarisangyaSuccess,
  deleteFamilyVarisangyaFail,
  autoGenerateFamilyVarisangyasSuccess,
  autoGenerateFamilyVarisangyasFail,
  refreshFamilyVarisangyaSuccess,
  refreshFamilyVarisangyaFail,
  updateFamilyVarisangyaAmountByFilterSuccess,
  updateFamilyVarisangyaAmountByFilterFail,
  getAllMainVarisangyasSuccess,
  getAllMainVarisangyasFail,
  createMainVarisangyaSuccess,
  createMainVarisangyaFail,
  updateMainVarisangyaSuccess,
  updateMainVarisangyaFail,
  deleteMainVarisangyaSuccess,
  deleteMainVarisangyaFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { createVarisangyaFail, createVarisangyaSuccess } from "store/actions"

const getMainVarisangyasV2APi = ({
  searchText,
  page,
  limit,
  type,
  ledgerId,
  mahallId,
  period,
  isAutopay,
}) => {
  console.log(searchText)

  if (searchText) {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya/?search=${
        searchText && searchText
      }&page=${page && page}&page_size=${limit ? limit : 10}&type=${
        type || ""
      }&ledger=${ledgerId || ""}&mahall=${mahallId || ""}&period=${
        period || ""
      }&autopay=${isAutopay || ""}`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya/?page=${
        page ? page : 1
      }&page_size=${limit ? limit : 10}&type=${type || ""}&ledger=${
        ledgerId || ""
      }&mahall=${mahallId || ""}&period=${period || ""}&autopay=${
        isAutopay || ""
      }`
    )
  }
}

const autoGenerateFamilyVarisangyasAPi = () => {
  return post(`/api/v1/varisankya/family_varisankya_auto_generate/`)
}

const refreshFamilyVarisangyaAPi = ({ familyVarisangyaId }) => {
  console.log("familyVarisangyaId: ", familyVarisangyaId)
  return post(
    `/api/v1/varisankya/family_varisankya_refresh/${familyVarisangyaId}/`
  )
}

const updateFamilyVarisangyaAmountByFilterApi = ({
  familyVarisangyaAmuontByFilter,
}) => {
  return post(
    "/api/v1/varisankya/family_varisankya_amount_update_in_filter/",
    familyVarisangyaAmuontByFilter,
    { "Content-type": "" }
  )
}

const getFamilyVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/family_varisankya/?cards=all`)
}
const getFamilyVarisangyaDetailsAPi = familyVarisangyaId => {
  return get(`/api/v1/varisankya/family_varisankya/${familyVarisangyaId}/`)
}
const createVarisangyaApi = ({ varisangyaData }) => {
  return post("/api/v2/varisankya/mahall-user/varisankya/", varisangyaData)
}
const updateMainVarisangyaApi = ({ varisangyaId, varisangyaData }) => {
  return ApiPut(
    `/api/v2/varisankya/mahall-user/varisankya/${varisangyaId}/`,
    varisangyaData
  )
}
const deleteMainVarisangyaApi = ({ varisangyaId }) => {
  return del(`/api/v2/varisankya/mahall-user/varisankya/${varisangyaId}/`)
}

function* fetchAllMainVarisangyas({ payload }) {
  try {
    const response = yield call(getMainVarisangyasV2APi, payload)
    if (response && !response?.error) {
      yield put(getAllMainVarisangyasSuccess(response))
    } else {
      yield put(getAllMainVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllMainVarisangyasFail(error))
  }
}

function* autoGenerateFamilyVarisangyas() {
  try {
    const response = yield call(autoGenerateFamilyVarisangyasAPi)
    if (response && !response?.error) {
      yield put(autoGenerateFamilyVarisangyasSuccess(response))
      try {
        const response2 = yield call(getMainVarisangyasV2APi, { page: 1 })
        // yield put(getFamilyVarisangyasSuccess(response2))
      } catch (error) {
        // yield put(getFamilyVarisangyasFail(error))
      }
    } else {
      yield put(autoGenerateFamilyVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    // yield put(getFamilyVarisangyasFail(error))
  }
}

function* refreshFamilyVarisangya({ payload }) {
  console.log(payload)
  try {
    const response = yield call(refreshFamilyVarisangyaAPi, payload)
    if (response && !response?.error) {
      yield put(refreshFamilyVarisangyaSuccess(response))
      doneNotification(response?.response)
      try {
        const response2 = yield call(
          getFamilyVarisangyaDetailsAPi,
          payload?.familyVarisangyaId
        )
        yield put(getFamilyVarisangyaDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyVarisangyaDetailFail(error))
      }
    } else {
      yield put(refreshFamilyVarisangyaFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(refreshFamilyVarisangyaFail(error))
  }
}

function* updateFamilyVarisangyaAmountByFilter({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(
      updateFamilyVarisangyaAmountByFilterApi,
      payload
    )
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(updateFamilyVarisangyaAmountByFilterSuccess(response))
      payload.history.push("/family/wallet/details")
      doneNotification("Created Successfully")
    } else {
      yield put(updateFamilyVarisangyaAmountByFilterFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(updateFamilyVarisangyaAmountByFilterFail(error))
    errorNotification()
  }
}

function* fetchFamilyVarisangyasCards() {
  try {
    const response = yield call(getFamilyVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaCardsSuccess(response))
    } else {
      yield put(getFamilyVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaCardsFail(error))
  }
}

function* fetchFamilyVarisangyaDetail({ familyVarisangyaId }) {
  try {
    const response = yield call(
      getFamilyVarisangyaDetailsAPi,
      familyVarisangyaId
    )
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaDetailSuccess(response))
    } else {
      yield put(getFamilyVarisangyaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaDetailFail(error))
  }
}
function* onCreateVarisangya({ payload }) {
  try {
    const response = yield call(createVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(createMainVarisangyaSuccess(response))
      payload?.handleClearLedgerField()
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createMainVarisangyaFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    console.log("error: ", error)
    yield put(createMainVarisangyaFail(error))
    errorNotification()
  }
}

function* onUpdateVarisangya({ payload }) {
  try {
    const response = yield call(updateMainVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(updateMainVarisangyaSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateMainVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateMainVarisangyaFail(error))
    errorNotification()
  }
}

function* onDeleteMainVarisangya({ payload }) {
  try {
    const response = yield call(deleteMainVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(deleteMainVarisangyaSuccess(payload.varisangyaId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteMainVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteMainVarisangyaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* MainVarisangyasSaga() {
  yield takeEvery(GET_ALL_MAIN_VARISANGYAS, fetchAllMainVarisangyas)
  yield takeEvery(GET_FAMILY_VARISANGYA_CARDS, fetchFamilyVarisangyasCards)
  yield takeEvery(GET_FAMILY_VARISANGYA_DETAIL, fetchFamilyVarisangyaDetail)
  yield takeEvery(CREATE_MAIN_VARISANGYA, onCreateVarisangya)
  yield takeEvery(UPDATE_MAIN_VARISANGYA, onUpdateVarisangya)
  yield takeEvery(DELETE_MAIN_VARISANGYA, onDeleteMainVarisangya)
  yield takeEvery(
    AUTO_GENERATE_FAMILY_VARISANGYAS,
    autoGenerateFamilyVarisangyas
  )
  yield takeEvery(REFRESH_FAMILY_VARISANGYA, refreshFamilyVarisangya)
  yield takeEvery(
    UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
    updateFamilyVarisangyaAmountByFilter
  )
}

export default MainVarisangyasSaga
