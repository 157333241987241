import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardTitle,
  CardBody,
  Spinner,
  Button,
  Badge,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import {
  // debounce,
  map,
  range,
} from "lodash"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//actions family

import {
  deleteFamily,
  getAllFamilyMemberVarisangyaWallet,
  getFamilyMemberDetail,
  getFamilyMemberVarisangyaWalletDetail,
  getVarisangyaWalletDetail,
} from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import MyPagination from "components/Common/MyPagination"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import UiModalAddFamilyMemberVarisangyaArrear from "./UiModalAddFamilyMemberVarisangyaArrear"
import MyPaginationNew from "components/Common/MyPagination2"

//Import Images

const FamilyMemberWalletDetail = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const [searchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalAddArrear, setModalAddArrear] = useState(false)

  const {
    familyMemberDetail,
    // loading,
    allFamilyMemberVarisangyaWallet,
    tableLoading,
    language,
    // familyVarisangyaItems,
    walletDetail,
  } = useSelector(state => ({
    loading: state.VarisangyaWallets.tableLoading,
    detailLoading: state.Families.detailLoading,
    language: state.Layout.language,
    familyMemberDetail: state.FamilyMembers.familyMemberDetail,
    allFamilyMemberVarisangyaWallet:
      state.VarisangyaWallets.allFamilyMemberVarisangyaWallet,
    tableLoading: state.FamilyMemberVarisangyaWallets.tableLoading,
    walletDetail: state.VarisangyaWallets.varisangyaWalletDetail,
  }))

  const totalPages = Math.ceil(allFamilyMemberVarisangyaWallet?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  console.log(location)

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  const handleDeleteEvent = () => {
    dispatch(deleteFamily(familyMemberDetail?.id, history))
    setIsOpen(false)
    // history.push("/families")
  }

  useEffect(() => {
    dispatch(getFamilyMemberDetail(params.walletId))
  }, [dispatch, params.walletId])

  useEffect(() => {
    dispatch(getVarisangyaWalletDetail(location?.state?.walletId, params?.type))
  }, [params?.walletId, params?.type])

  useEffect(() => {
    dispatch(
      getAllFamilyMemberVarisangyaWallet(
        pageSend(),
        searchText,
        limit,
        location?.state?.walletId
      )
    )
  }, [dispatch, pageSend(), limit, searchText])

  // useEffect(() => {
  //   dispatch(getFamilyVarisangyaItems(params.id))
  // }, [dispatch, params.id])

  // useEffect(() => {
  //   // dispatch(getFamilyVarisangyaWalletDetail(params.id, page, searchText))
  //   dispatch(
  //     getFamilyMemberVarisangyaWalletDetail(params.id, page, searchText, "")
  //   )
  // }, [page, searchText, params.id])

  // const debounceMahallFamilyFamilyMemberSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "balance",
      text: language === "mal" ? "ബാലൻസ്" : "Balance",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "വിവരണം" : "Remarks",
    },
    // {
    //   dataField: "action",
    //   text: language === 'mal' ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    // }
  ]

  const familyMemberVarisangyaWalletsData = map(
    allFamilyMemberVarisangyaWallet?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      amount: (
        <p
          className={
            item?.trn_type === "credit" ? "text-success" : "text-danger"
          }
        >
          {item?.amount}
        </p>
      ),
      balance: <p className="text-primary">{item?.balance}</p>,
      date: item?.date_added && moment(item?.date_added).format("DD/MM/yyyy"),
      // action: (
      //   <div className="m-0">
      //     <Link to={`/varisangya/update/${item?.id}`} className="m-0">
      //       <Button
      //         type="button"
      //         color="primary"
      //         className=" btn w-xs btn-sm"
      //       >
      //         {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
      //         {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
      //       </Button>
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // function getAge(dateString) {
  //   var today = new Date()
  //   var birthDate = new Date(dateString)
  //   if (dateString) {
  //     const startDate = moment(today)
  //     const timeEnd = moment(birthDate)
  //     const diff = startDate.diff(timeEnd)
  //     var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
  //     // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
  //     // console.log("age: ", age)
  //     if (age < 0) age = 0
  //     return age
  //   } else {
  //     return 0
  //   }
  // }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalAddFamilyMemberVarisangyaArrear
        show={modalAddArrear}
        onCloseclick={() => setModalAddArrear(false)}
        data={walletDetail}
        page="wallet"
      />

      <Row>
        <Col md={12} lg={8}>
          {/* <div className="col-md-6 p-3 mb-3" style={{ backgroundColor: "#c3fade", borderRadius: "8px" }}>
                  <h4 className="text-khaf-blue">Wallet</h4>
                  <p style={{ fontSize: "16px", color: "black" }}>Family : {familyDetail?.house_name}</p>
                  <p style={{ fontSize: "16px", color: "black" }}>Mahall ID : {familyDetail?.mahall_custom_id}</p>
                  <p style={{ fontSize: "16px", marginBottom: "0px", color: "black" }}>Available Balance : {familyDetail?.varisankya_wallet}</p>
                </div> */}
          {walletDetail && (
            <Row>
              <Col md="12">
                <Card style={{ backgroundColor: "#e6fcee" }}>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <h5 className="font-size-13 text-primary font-weight-bold">
                          {walletDetail?.family_name}
                        </h5>

                        <h5 className="font-size-13 text-primary font-weight-bold">
                          {walletDetail?.varisankya_name}
                        </h5>

                        <div className="d-flex">
                          <h5 className="font-size-22 font-weight-bold mt-1">
                            {walletDetail?.family_member_name}
                          </h5>
                        </div>

                        {walletDetail?.mahall_custom_id && (
                          <Badge
                            className="bg-primary rounded-pill mx-2 bg-success"
                            style={{ fontSize: "12px" }}
                          >
                            {language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"} :{" "}
                            {walletDetail?.mahall_custom_id}
                          </Badge>
                        )}

                        {walletDetail?.phone && (
                          <p className="mt-4">
                            <i className="bx bx-phone text-primary me-1" />
                            {walletDetail?.phone}
                          </p>
                        )}
                      </Col>
                      {/* <Col md="6" className="">
                        {familyMemberDetail?.id_card && (
                          <h5 className="font-size-13 text-muted font-weight-bold mt-4">
                            {language === "mal"
                              ? familyMemberDetail?.id_card?.malayalam
                              : familyMemberDetail?.id_card?.english}{" "}
                            : {familyMemberDetail?.id_card_number}
                          </h5>
                        )}
                        <h5 className="font-size-14 mt-2">
                          {familyMemberDetail?.father_name &&
                            (familyMemberDetail?.gender === "male"
                              ? `S/o `
                              : `D/o `)}
                          {familyMemberDetail?.father_id ? (
                            <Link
                              to={`/familymembers/${familyMemberDetail?.father_id}`}
                            >
                              {familyMemberDetail?.father_name}
                            </Link>
                          ) : (
                            familyMemberDetail?.father_name
                          )}

                          {familyMemberDetail?.mother_name && (
                            <>
                              {familyMemberDetail?.mother_id ? (
                                <Link
                                  to={`/familymembers/${familyMemberDetail?.mother_id}`}
                                >
                                  {familyMemberDetail?.mother_name}
                                </Link>
                              ) : (
                                ` & ${familyMemberDetail?.mother_name}`
                              )}
                            </>
                          )}
                        </h5>
                        <h5 className="font-size-14 mt-1">
                          {familyMemberDetail?.spouse_name &&
                            `Married to ${familyMemberDetail?.spouse_name}`}
                        </h5>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Card>
            <Col className="pt-3 px-3">
              <Row className="d-flex align-items-center">
                <Col className="d-flex align-items-center">
                  <p className="font-size-18">
                    {language === "mal"
                      ? "വാലറ്റ് ബാലൻസ് : "
                      : "Wallet Balance : "}
                    <span
                      className={`fw-bold + ${
                        walletDetail?.balance == 0
                          ? "text-primary"
                          : walletDetail?.balance > 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {walletDetail?.balance}
                    </span>
                  </p>
                </Col>
                <Col>
                  <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{
                      width: language === "mal" ? "170px" : "150px",
                    }}
                    className="btn btn-danger m-auto me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === "mal" ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Arrears"}
                  </Button>
                </Col>
              </Row>
            </Col>
            <hr />
            <CardTitle className="mx-3 mt-3">
              {language === "mal" ? "ഇടപാടുകൾ :" : "Transactions :"}
            </CardTitle>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={familyMemberVarisangyaWalletsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {/* <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e => debouncefamilyVarisangyaWalletSearch(e.target.value)}
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                    {tableLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {(!allFamilyMemberVarisangyaWallet?.results ||
                          allFamilyMemberVarisangyaWallet?.results?.length <=
                            0) && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info"
                          >
                            {language === "mal"
                              ? "ഇടപാടുകൾ ഒന്നും ഇല്ല"
                              : "No Transactions Yet"}
                          </p>
                        )}
                        <MyPaginationNew
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {/* {familyVarisangyaWallets && familyVarisangyaWallets?.results?.length <= 0 && (
                      <p style={{ fontSize: "15px" }} className="text-center text-info">{language === 'mal' ? "വരിസംഖ്യകൾ ഒന്നും ഇല്ല" : "No Varisangyas Yet"}</p>
                    )} */}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FamilyMemberWalletDetail

FamilyMemberWalletDetail.propTypes = {
  history: PropTypes.object,
}
