import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_DETAIL,
  REFRESH_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  DELETE_MAIN_VARISANGYA,
  GET_ALL_VARISANGYA_PERIOD,
  AUTO_GENERATE_VARISANGYA_PERIOD,
  CREATE_VARISANGYA_PERIOD,
  UPDATE_VARISANGYA_PERIOD,
  DELETE_VARISANGYA_PERIOD,
  GET_VARISANGYA_PERIOD_DETAIL,
  GET_ALL_VARISANGYA_PERIOD_ITEMS,
  REFRESH_VARISANGYA_PERIOD_ITEM,
  DELETE_VARISANGYA_PERIOD_ITEM,
} from "./actionTypes"
import {
  getFamilyVarisangyaCardsSuccess,
  getFamilyVarisangyaCardsFail,
  getFamilyVarisangyaDetailSuccess,
  getFamilyVarisangyaDetailFail,
  refreshFamilyVarisangyaSuccess,
  refreshFamilyVarisangyaFail,
  updateFamilyVarisangyaAmountByFilterSuccess,
  updateFamilyVarisangyaAmountByFilterFail,
  createVarisangyaPeriodSuccess,
  deleteMainVarisangyaSuccess,
  deleteMainVarisangyaFail,
  getAllVarisangyaPeriodSuccess,
  getAllVarisangyaPeriodFail,
  autoGenerateVarisangyaPeriodSuccess,
  autoGenerateVarisangyaPeriodFail,
  getAllVarisangyaPeriod,
  createVarisangyaPeriodFail,
  updateVarisangyaPeriodSuccess,
  updateVarisangyaPeriodFail,
  deleteVarisangyaPeriodFail,
  deleteVarisangyaPeriodSuccess,
  getVarisangyaPeriodDetailSuccess,
  getVarisangyaPeriodDetailFail,
  getAllVarisangyaPeriodItemsSuccess,
  getAllVarisangyaPeriodItemsFail,
  refreshVarisangyaPeriodItemSuccess,
  refreshVarisangyaPeriodItemFail,
  deleteVarisangyaPeriodItemSuccess,
  deleteVarisangyaPeriodItemFail,
  getAllVarisangyaPeriodItems,
} from "./actions"

import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getVarisangyaPeriodV2APi = ({
  searchText,
  page,
  limit,
  variansangyaId,
}) => {
  if (searchText) {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-period/?search=${
        searchText && searchText
      }&page=${page && page}&page_size=${limit ? limit : 10}&varisankya=${
        variansangyaId || ""
      }`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-period/?page=${
        page ? page : 1
      }&page_size=${limit ? limit : 10}&varisankya=${variansangyaId || ""}`
    )
  }
}

const getVarisangyaPeriodItemV2APi = ({
  searchText,
  page,
  limit,
  varisangyaPeriodId,
  type,
}) => {
  console.log(searchText)

  if (type === "family") {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-item/?search=${
        searchText || ""
      }&page=${page ? page : 1}&page_size=${
        limit ? limit : 10
      }&varisankya_period=${varisangyaPeriodId || ""}`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-item/?page=${
        page ? page : 1
      }&page_size=${limit ? limit : 10}&varisankya_period=${
        varisangyaPeriodId || ""
      }&search=${searchText || ""}`
    )
  }
}

const deleteVarisangyaPeriodItemApi = ({ varisangyaPeriodItemId, type }) => {
  if (type === "family") {
    return del(
      `/api/v2/varisankya/mahall-user/varisankya-family-item/${varisangyaPeriodItemId}/`
    )
  } else {
    return del(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-item/${varisangyaPeriodItemId}/`
    )
  }
}

const autoGenerateVarisangyaPeriodAPi = ({ varisangyaId }) => {
  return post(
    `/api/v2/varisankya/mahall-user/varisankya-auto-generate/?varisankya=${varisangyaId}`
  )
}

const refreshVarisangyaPeriodItemAPi = ({ varisangyaPeriodId, type }) => {
  if (type === "family") {
    return post(
      `/api/v2/varisankya/mahall-user/varisankya-period/${varisangyaPeriodId}/refresh/family/`
    )
  } else {
    return post(
      `/api/v2/varisankya/mahall-user/varisankya-period/${varisangyaPeriodId}/refresh/member/`
    )
  }
}

const updateFamilyVarisangyaAmountByFilterApi = ({
  familyVarisangyaAmuontByFilter,
}) => {
  return post(
    "/api/v1/varisankya/family_varisankya_amount_update_in_filter/",
    familyVarisangyaAmuontByFilter,
    { "Content-type": "" }
  )
}

const getFamilyVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/family_varisankya/?cards=all`)
}
const getVarisangyaPeriodDetailsAPi = varisangyaPeriodId => {
  return get(
    `/api/v2/varisankya/mahall-user/varisankya-period/${varisangyaPeriodId}/`
  )
}
const createVarisangyaPeriodApi = ({ varisangyaPeriodData }) => {
  return post(
    "/api/v2/varisankya/mahall-user/varisankya-period/",
    varisangyaPeriodData
  )
}
const updateVarisangyaPeriodApi = ({
  varisangyaPeriodData,
  varisangyaPeriodId,
}) => {
  return patch(
    `api/v2/varisankya/mahall-user/varisankya-period/${varisangyaPeriodId}/`,
    varisangyaPeriodData
  )
}
const deleteVarisangyaPeriodApi = ({ varisangyaPeriodId }) => {
  return del(
    `api/v2/varisankya/mahall-user/varisankya-period/${varisangyaPeriodId}/`
  )
}

function* fetchAllVarisangyaPeriod({ payload }) {
  try {
    const response = yield call(getVarisangyaPeriodV2APi, payload)
    if (response && !response?.error) {
      yield put(getAllVarisangyaPeriodSuccess(response))
    } else {
      yield put(getAllVarisangyaPeriodFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllVarisangyaPeriodFail(error))
  }
}

function* autoGenerateVarisangyaPeriod({ payload }) {
  try {
    const response = yield call(autoGenerateVarisangyaPeriodAPi, payload)
    if (response && !response?.error) {
      yield put(autoGenerateVarisangyaPeriodSuccess(response))
      yield put(getAllVarisangyaPeriod("", "", "", payload?.varisangyaId))
      doneNotification()
      try {
        // const response2 = yield call(getVarisangyaPeriodV2APi, { page: 1 })
        // yield put(getFamilyVarisangyasSuccess(response2))
      } catch (error) {
        // yield put(getFamilyVarisangyasFail(error))
      }
    } else {
      yield put(autoGenerateVarisangyaPeriodFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log(error)

    // yield put(getFamilyVarisangyasFail(error))
  }
}

function* refreshVarisangyaPeriodItem({ payload }) {
  console.log(payload)
  try {
    const response = yield call(refreshVarisangyaPeriodItemAPi, payload)
    if (response && !response?.error) {
      yield put(refreshVarisangyaPeriodItemSuccess(response))
      doneNotification(response?.response)
      try {
        const response2 = yield call(
          getVarisangyaPeriodDetailsAPi,
          payload?.varisangyaPeriodId
        )
        yield put(getVarisangyaPeriodDetailSuccess(response2))
      } catch (error) {
        yield put(getVarisangyaPeriodDetailFail(error))
      }
    } else {
      yield put(refreshVarisangyaPeriodItemFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(refreshVarisangyaPeriodItemFail(error))
  }
}

function* updateFamilyVarisangyaAmountByFilter({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(
      updateFamilyVarisangyaAmountByFilterApi,
      payload
    )
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(updateFamilyVarisangyaAmountByFilterSuccess(response))
      payload.history.push("/family/wallet/details")
      doneNotification("Created Successfully")
    } else {
      yield put(updateFamilyVarisangyaAmountByFilterFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(updateFamilyVarisangyaAmountByFilterFail(error))
    errorNotification()
  }
}

function* fetchFamilyVarisangyasCards() {
  try {
    const response = yield call(getFamilyVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaCardsSuccess(response))
    } else {
      yield put(getFamilyVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaCardsFail(error))
  }
}

function* fetchVarisangyaPeriodDetail({ varisangyaPeriodId }) {
  try {
    const response = yield call(
      getVarisangyaPeriodDetailsAPi,
      varisangyaPeriodId
    )
    if (response && !response?.error) {
      yield put(getVarisangyaPeriodDetailSuccess(response))
    } else {
      yield put(getVarisangyaPeriodDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaPeriodDetailFail(error))
  }
}

// period item

function* fetchAllVarisangyaPeriodItems({ payload }) {
  try {
    const response = yield call(getVarisangyaPeriodItemV2APi, payload)
    if (response && !response?.error) {
      yield put(getAllVarisangyaPeriodItemsSuccess(response))
    } else {
      yield put(getAllVarisangyaPeriodItemsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllVarisangyaPeriodItemsFail(error))
  }
}

function* onDeleteVarisangyaPeriodItem({ payload }) {
  try {
    const response = yield call(deleteVarisangyaPeriodItemApi, payload)
    if (response) {
      yield put(
        deleteVarisangyaPeriodItemSuccess(payload.varisangyaPeriodItemId)
      )
      doneNotification("Deleted Successfully")
      yield put(
        getAllVarisangyaPeriodItems(
          payload?.periodId,
          "",
          "",
          "",
          payload?.type
        )
      )
    } else {
      yield put(deleteVarisangyaPeriodItemFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log(error)

    errorNotification()
    yield put(deleteVarisangyaPeriodItemFail(error))
  }
}

function* onCreateVarisangyaPeriod({ payload }) {
  try {
    const response = yield call(createVarisangyaPeriodApi, payload)
    if (response && !response?.error) {
      yield put(createVarisangyaPeriodSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createVarisangyaPeriodFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    console.log("error: ", error)
    yield put(createVarisangyaPeriodFail(error))
    errorNotification()
  }
}

function* onUpdateVarisangyaPeriod({ payload }) {
  try {
    const response = yield call(updateVarisangyaPeriodApi, payload)
    if (response && !response?.error) {
      yield put(updateVarisangyaPeriodSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateVarisangyaPeriodFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateVarisangyaPeriodFail(error))
    errorNotification()
  }
}

function* onDeleteVarisangyaPeriod({ payload }) {
  try {
    const response = yield call(deleteVarisangyaPeriodApi, payload)
    if (response && !response?.error) {
      yield put(deleteVarisangyaPeriodSuccess(payload.varisangyaPeriodId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteVarisangyaPeriodFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteVarisangyaPeriodFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* VarisangyaPeriodSaga() {
  yield takeEvery(GET_ALL_VARISANGYA_PERIOD, fetchAllVarisangyaPeriod)
  yield takeEvery(
    GET_ALL_VARISANGYA_PERIOD_ITEMS,
    fetchAllVarisangyaPeriodItems
  )
  yield takeEvery(GET_FAMILY_VARISANGYA_CARDS, fetchFamilyVarisangyasCards)
  yield takeEvery(GET_VARISANGYA_PERIOD_DETAIL, fetchVarisangyaPeriodDetail)
  yield takeEvery(CREATE_VARISANGYA_PERIOD, onCreateVarisangyaPeriod)
  yield takeEvery(UPDATE_VARISANGYA_PERIOD, onUpdateVarisangyaPeriod)
  yield takeEvery(DELETE_VARISANGYA_PERIOD, onDeleteVarisangyaPeriod)
  yield takeEvery(DELETE_VARISANGYA_PERIOD_ITEM, onDeleteVarisangyaPeriodItem)
  yield takeEvery(AUTO_GENERATE_VARISANGYA_PERIOD, autoGenerateVarisangyaPeriod)
  yield takeEvery(REFRESH_VARISANGYA_PERIOD_ITEM, refreshVarisangyaPeriodItem)
  yield takeEvery(
    UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
    updateFamilyVarisangyaAmountByFilter
  )
}

export default VarisangyaPeriodSaga
